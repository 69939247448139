import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  aboutData,
  aboutGuideDataOne,
  aboutGuideDataTwo,
} from "../Assets/data/Contents";
import BannerImage from "../Assets/Images/EVR_LOGO.png";
import AboutCard from "../ReusedComponentssssssssssssssssssss/Cards/AboutCard";
import LogoSlider from "./LogoSlider";
import Contact from "./Contact";
import Footer from "./Footer";
import { motion } from "framer-motion";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const createRondDiv = () => {
    const divs = [];
    for (let i = 0; i < 7; i++) {
      divs.push(
        <motion.div
          initial={{ scale: 2 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          viewport={{ once: true }}
          key={i}
          className={`round-div-${i + 1}`}
        ></motion.div>
      );
    }

    return <div>{divs}</div>;
  };

  return (
    <motion.main
      className="position-relative"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className="mobile_logo">
        <img src={BannerImage} alt="EvolvierLogo" />
      </div>
      <Container className=" pt-lg-5 pt-5 defult-mb defult-mt mt-5 mb-5">
        <div className="text-center px-lg-5 mt-lg-5">
          <h1 className="abt-h1 defult-mb mb-3">About Us</h1>
          <span className="about-phrgh ">
            We are a driven team of tech enthusiasts dedicated to providing
            comprehensive IT solutions tailored to your business needs. From
            conceptualization to implementation and ongoing support, Evolvier
            Technologies is here to simplify your journey. Specializing in
            mobile app and web app development, as well as backend development,
            we ensure a seamless digital experience for your users. Moreover, we
            excel in integrating various systems and technologies to offer
            complete solutions. Whether you are in Travel, Tourism, F&B, or
            Culinary & Gourmet specialized apps, our expertise spans across
            domains to enhance your business operations. With a focus on
            artificial intelligence, analytics, and security, we are committed
            to optimizing your processes and driving success in todays digital
            era.
          </span>
        </div>
      </Container>

      <Container className="text-center py-md-5 defult-mb">
        <h1 className="abt-h1 mb-3">Our Creative Process</h1>
        <p className="abut-p">
          We go with agile development, so feel free to share your ideas and
          changes anytime during the project. We&apos;re all about working
          together to make sure the final product is just what you envisioned.{" "}
        </p>
      </Container>

      <Container className="mt-5 defult-mb mb-md-5">
        <Row className="justify-content-between">
          {aboutData.map((data, i) => {
            return (
              <Col lg={4} sm={12} md={6} key={i} className="mb-4" N>
                <motion.div
                  className="abot-content-div d-flex"
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  whileHover={{ transform: "translateY(-10px)" }}
                  transition={{
                    duration: 0.5,
                    ease: "easeInOut",
                    delay: (i * 10) / 100,
                  }}
                  viewport={{ once: true }}
                >
                  <div className="abt-cont p-3 p-md-0">
                    <div className="d-flex  mb-3">
                      <motion.div
                        initial={{ scale: 0 }}
                        whileInView={{ scale: 1 }}
                        transition={{
                          duration: 0.5,
                          ease: "easeIn",
                          delay: (i * 10) / 100,
                        }}
                        viewport={{ once: true }}
                        className="color_point me-lg-3"
                        style={{ background: `${data.bg}` }}
                      />
                      <h5>{data.title}</h5>
                    </div>
                    <p>{data.desc}</p>
                  </div>
                </motion.div>
              </Col>
            );
          })}
        </Row>
      </Container>

      <Container className="text-center">
        <h1 className="abt-h1">Guided Journey: A Walkthrough of the Process</h1>
        <p className="abut-p mt-lg-5 mt-4 defult-mb">
          We maintain an open and transparent software development lifecycle,
          providing you with a clear roadmap for the successful conclusion of
          your project.
        </p>

        <Row className="mt-5 justify-content-xxl-evenly justify-content-lg-between position-relative">
          <Col lg={5} className="text-end abt-card-box">
            {aboutGuideDataOne.map((data, i) => {
              return (
                <AboutCard
                  {...data}
                  key={i}
                  borderValue={"50px 2px 50px 50px"}
                />
              );
            })}
          </Col>

          <Col
            lg={5}
            className=" text-start d-flex flex-column justify-content-center abt-card-box"
          >
            {aboutGuideDataTwo.map((data, i) => {
              return (
                <AboutCard
                  {...data}
                  key={i}
                  borderValue={"2px 50px 50px 50px"}
                />
              );
            })}
          </Col>

          <div className="about_svg_wraper">
            <svg
              width="6"
              height="831"
              viewBox="0 0 6 831"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="2.99609"
                y1="2.75586"
                x2="2.99606"
                y2="828.473"
                stroke="#0095FE"
                strokeWidth="5"
                strokeLinecap="round"
              />
            </svg>

            {createRondDiv()}
          </div>
        </Row>
      </Container>

      <Container className="text-center defult-mb my-5">
        <h1 className="abt-h1 mb-4">Technology Expertise</h1>
        <p className="abut-p">
          Our technology expertise includes, but is not limited to, the
          following:
        </p>
      </Container>

      <div className="my-xxl-5 pt-xxl-5">
        <LogoSlider />
      </div>
      <div className="wraper-contact">
        <Contact />
      </div>
      <Footer />
    </motion.main>
  );
};

export default AboutUs;
