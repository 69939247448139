import React from "react";
import { motion } from "framer-motion";

const FeedbackCard = ({ userName }) => {
  console.log("name", userName);
  return (
    <motion.div className="feedback-card p-3 p-lg-5">
      <h1 className="mb-lg-4 mb-3">Hi {userName}</h1>
      <p className="feedback-desc">
        Thank you for reaching out to Evolvier Technologies. We appreciate your
        message and will respond to you as promptly as we can. Let&#39;s work
        together to build something great.
      </p>
      <p className="text-end feedback-team">-team Evolvier</p>
    </motion.div>
  );
};

export default FeedbackCard;
