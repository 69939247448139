import React from "react";
import { motion } from "framer-motion";

const Preloader = () => {
  return (
    <motion.div className="item d-flex flex-column">
      <div className="loader"></div>
      {/* <div className="container preloder-container mt-3 mt-lg-0">
        <motion.h1
          className="text-center preloder-h1 "
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
        >
          Where Creativity Meets Freedom
        </motion.h1>
      </div> */}
    </motion.div>
  );
};

export default Preloader;
