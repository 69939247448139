import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactModal from "../ReusedComponentssssssssssssssssssss/modal/ContactModal";
import { motion } from "framer-motion";
import { HashLink as Link } from "react-router-hash-link";
import { footerAnimationData, footerDesignData } from "../Assets/data/Contents";
import FooterLogo from "../Assets/Images/EVR_footer_logo.png";

const Footer = () => {
  const refOne = useRef(null);
  const mailOne = "career@evolvier.com";
  const mailTwo = "support@evolvier.com";

  const footerServiceData = [
    "Cross Platform App Development",
    "Native App Development",
    "Web App Development",
    "Design & User Experience",
    "Maintenance & App monitoring",
    "Database solutions",
    "Backend and API development",
    "Cloud Service",
    "Digital Marketing",
    "End to End Solutions",
  ];

  return (
    <footer className="py-5 px-2 px-md-0 px-lg-0">
      {/* top footer section not showing in mobile devices */}
      <section className="hideInMobile">
        <Container className="pro-container">
          <div className="footerLogo">
            <img src={FooterLogo} alt="EvolvierFooter_logo" />
          </div>
          <Row className="pt-lg-5 about-row my-md-5 my-lg-0">
            {/* card-1 */}
            <Col
              lg={4}
              md={6}
              sm={6}
              className="d-flex justify-content-start mb-5 mb-lg-5"
            >
              <div className="top-footer-card">
                <h3 className="footer-heades">Join us.</h3>
                <a
                  className="footer-contens"
                  href="mailto:career@evolvier.com"
                  onClick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "Gmail_Url_Click",
                      Mail_Urls: `${mailOne} clicked`,
                    });
                  }}
                >
                  {mailOne}
                </a>
              </div>
            </Col>
            {/* card-2 */}
            <Col
              lg={4}
              md={6}
              sm={6}
              className="d-flex justify-content-start justify-content-lg-center mb-5 mb-lg-5"
            >
              <div className="top-footer-card">
                <h3 className="footer-heades">Ask us.</h3>
                <a
                  className="footer-contens "
                  href="mailto:support@evolvier.com"
                  onClick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "Gmail_Url_Click",
                      Mail_Urls: `${mailTwo} clicked`,
                    });
                  }}
                >
                  {mailTwo}
                </a>
                {/* <p className="footer-contens">support@evolvier.com</p> */}
              </div>
            </Col>
            {/* card-3 */}
            <Col
              lg={4}
              md={6}
              sm={6}
              className="d-flex justify-content-start justify-content-lg-center mb-5 mb-lg-5"
            >
              <div className="top-footer-card">
                <h3 className="footer-heades">Call us.</h3>
                <p className="footer-contens">+91 9809234516</p>
              </div>
            </Col>
            {/* card-4 */}
            <Col
              lg={4}
              md={6}
              sm={6}
              className="d-flex justify-content-start justify-content-lg-center mb-5 mb-lg-0 ms-xxl-0 ps-xxl-0"
            >
              <div className="top-footer-card">
                <h3 className="footer-heades">Visit us.</h3>
                <p className="footer-contens" id="footer-contens">
                  Technolodge, Kakkoor Ernakulam, Kerala, IN
                </p>
              </div>
            </Col>
            {/* card-5 */}
            <Col
              lg={4}
              md={6}
              sm={6}
              className="d-flex justify-content-lg-end mb-5 pe-xxl-5 ms-lg-4"
            >
              <div className="top-footer-card">
                <h3 className="footer-heades">Like us.</h3>
                <div className="d-flex socila-url">
                  <a
                    href="https://www.linkedin.com/company/evolvier"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social_media"
                    onClick={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "SocialMedia_Links",
                        Social_urls: "Clicked LinkedIn Url",
                      });
                    }}
                  >
                    <p className="footer-contens me-3">linkedIn</p>
                  </a>

                  <a
                    href="https://www.facebook.com/EvolvierTechnologies/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social_media"
                    onClick={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "SocialMedia_Links",
                        Social_urls: "Clicked Facebook Url",
                      });
                    }}
                  >
                    <p className="footer-contens me-3">facebook</p>
                  </a>

                  <a
                    href="https://www.instagram.com/evolviertechnologies?igsh=cnVsdXBpNzd0aWFt"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social_media"
                    onClick={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "SocialMedia_Links",
                        Social_urls: "Clicked Instagram Url",
                      });
                    }}
                  >
                    <p className="footer-contens">instagram</p>
                  </a>

                  <a
                    href="https://twitter.com/evolvier"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social_media"
                    onClick={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "SocialMedia_Links",
                        Social_urls: "Clicked Instagram Url",
                      });
                    }}
                  >
                    <p className="footer-contens ms-2">X</p>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container className="my-5" ref={refOne}>
        <div className="overflow-hidden">
          <motion.h1 className="main-headlines mt-lg-0">
            Only Good Things Await
          </motion.h1>
        </div>
        {/* this section is showing in mobile and tab */}
        <div className="modal-btn-wraper d-flex justify-content-center justify-content-md-start my-5">
          <ContactModal />
        </div>

        <Row className="py-lg-5 flex-column-reverse flex-md-row d-fle justify-content-between">
          <Col lg={2} sm={12} className="hideInMobile mb-md-5">
            <div className="bottom-footer-card link_line">
              <h3 className="footer-heades mb-3 mb-xl-5">It’s all here.</h3>

              <Link
                smooth
                to="/services"
                id="footerServices"
                className="footer_urls"
                onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "Footer_Link_Clicks",
                    Url_Action: "Footer service-Url Clicked",
                  });
                }}
              >
                <p className="bottom-footer-contens">Services</p>
              </Link>
              <Link
                smooth
                to="/about"
                id="footerAbout"
                className="footer_urls"
                onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    // event: "Footer_Clik-about",
                    event: "Footer_Link_Clicks",
                    Url_Action: "Footer about-Url Clicked",
                  });
                }}
              >
                <p className="bottom-footer-contens">About</p>
              </Link>
              <Link
                smooth
                to="#contact"
                id="footerContact"
                className="footer_urls"
                onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    // event: "Footer_Clik-contactUs",
                    event: "Footer_Link_Clicks",
                    Url_Action: "Footer contactUs-Url Clicked",
                  });
                }}
              >
                <p className="bottom-footer-contens">Contact Us</p>
              </Link>
            </div>
          </Col>
          <Col lg={2} sm={12} className="hideInMobile mb-md-5">
            <div className="bottom-footer-card">
              <h3 className="footer-heades  mb-3">Services</h3>
              <p className="footer-sub-heads my-5 my-md-4 my-lg-5">
                Technology
              </p>
              {footerServiceData.map((list, i) => {
                return (
                  <p className="bottom-footer-contens" key={i}>
                    {list}
                  </p>
                );
              })}
            </div>
          </Col>
          <Col lg={2} className="mb-md-5 hideInMobile">
            <p className="footer-sub-heads  optional-margin">Animation & VFX</p>
            {footerAnimationData.map((data, i) => (
              <p className="bottom-footer-contens" key={i}>
                {data}
              </p>
            ))}
          </Col>
          <Col lg={2} className="hideInMobile">
            <p className="footer-sub-heads  optional-margin">
              Design & Concept Work
            </p>
            {footerDesignData.map((data, i) => (
              <p className="bottom-footer-contens" key={i}>
                {data}
              </p>
            ))}
          </Col>
        </Row>
        <span className="bottom-footer-span hideInMobile">
          © 2024 Evolvier. All Rights Reserved.
        </span>
      </Container>

      {/* SHOWING THIS SECTION ONLY IN MOBILE DEVICES */}
      <section className="hideOther">
        <Container>
          <div className="footerLogo">
            <img src={FooterLogo} alt="EvolvierFooter_logo" />
          </div>
          <Row className="pt-lg-5 about-row">
            {/* card-1 */}
            <Col
              lg={4}
              md={6}
              sm={6}
              className="d-flex justify-content-start mb-3 mb-lg-5"
            >
              <div className="top-footer-card">
                <h3 className="footer-heades">Join us.</h3>
                <a
                  className="footer-contens"
                  href="mailto:career@evolvier.com"
                  onClick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "Gmail_Url_Click",
                      Mail_Urls: `${mailOne} clicked`,
                    });
                  }}
                >
                  {mailOne}
                </a>
              </div>
            </Col>
            {/* card-2 */}
            <Col
              lg={4}
              md={6}
              sm={6}
              className="d-flex justify-content-start justify-content-lg-center mb-3 mb-lg-5"
            >
              <div className="top-footer-card">
                <h3 className="footer-heades">Ask us.</h3>
                <a
                  className="footer-contens "
                  href="mailto:support@evolvier.com"
                  onClick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "Gmail_Url_Click",
                      Mail_Urls: `${mailTwo} clicked`,
                    });
                  }}
                >
                  {mailTwo}
                </a>
                {/* <p className="footer-contens">support@evolvier.com</p> */}
              </div>
            </Col>
            {/* card-3 */}
            <Col
              lg={4}
              md={6}
              sm={6}
              className="d-flex justify-content-start justify-content-lg-center mb-3 mb-lg-5"
            >
              <div className="top-footer-card">
                <h3 className="footer-heades">Call us.</h3>
                <p className="footer-contens">+91 9809234516</p>
              </div>
            </Col>
            {/* card-4 */}
            <Col
              lg={4}
              md={6}
              sm={6}
              className="d-flex justify-content-start justify-content-lg-center mb-3 mb-lg-5 ps-xxl-4"
            >
              <div className="top-footer-card">
                <h3 className="footer-heades">Visit us.</h3>
                <p className="footer-contens" id="footer-contens">
                  Technolodge, Kakkoor Ernakulam, Kerala, IN
                </p>
              </div>
            </Col>
            {/* card-5 */}
            <Col
              lg={4}
              md={6}
              sm={6}
              className="d-flex justify-content-lg-end mb-5 pe-xxl-5 ms-lg-3"
            >
              <div className="top-footer-card">
                <h3 className="footer-heades">Like us.</h3>
                <div className="d-flex socila-url">
                  <a
                    href="https://www.linkedin.com/company/evolvier"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social_media"
                    onClick={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "SocialMedia_Links",
                        Social_urls: "Clicked LinkedIn Url",
                      });
                    }}
                  >
                    <p className="footer-contens me-3">linkedIn</p>
                  </a>
                  <a
                    href="https://www.facebook.com/EvolvierTechnologies/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social_media"
                    onClick={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "SocialMedia_Links",
                        Social_urls: "Clicked Facebook Url",
                      });
                    }}
                  >
                    <p className="footer-contens me-3">facebook</p>
                  </a>
                  <a
                    href="https://twitter.com/evolvier"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social_media"
                    onClick={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "SocialMedia_Links",
                        Social_urls: "Clicked Instagram Url",
                      });
                    }}
                  >
                    <p className="footer-contens">instagram</p>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
