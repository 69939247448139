import React from "react";

import { HashLink as Link } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import EVR_LOGO from "../Assets/Images/EVR_LOGO.png";
const Landingpage = () => {
  return (
    <header className="d-flex align-items-center justify-content-center position-relative">
      <div className="evr-landing-logo position-absolute d-lg-none">
        <img src={EVR_LOGO} alt="Evolvier-logo" />
      </div>

      <Carousel className="pt-md-5 mt-md-5 pt-lg-0 mt-lg-0">
        <Carousel.Item>
          <div className="d-flex flex-column justify-content-center align-items-center carousel_item_container  container">
            <h1 className="text-center  home-carousel-h1">
              Transforming your{" "}
              <span className="home-carousel-h1-span  pe-xl-3">
                ideas into successful apps
              </span>
            </h1>

            <div className="my-4 text-center inside-carousel my-5 my-xxl-0">
              <p className="home-carousel-p  text-center mb-4 my-xxl-4">
                Ready to elevate your business to new heights?
              </p>
              <span className="home-carousel-span my-5  ">
                In today&apos;s fast-paced world, digital transformation is
                revolutionizing industries. Join us and embrace the future by
                harnessing the power of Mobile and Web app development, Cloud
                Services, and Backend development for unparalleled growth.
              </span>
            </div>
            <Link smooth to="#services" className="mb-3">
              <button className=" button2">Discover What We Offer!</button>
            </Link>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="d-flex flex-column justify-content-center align-items-center carousel_item_container  container">
            <h1 className="text-center  home-carousel-h1">
              Comprehensive{" "}
              <span className="home-carousel-h1-span pe-3 ">
                Animation & VFX Solutions
              </span>
              at Affordable Rates
            </h1>

            <div className="my-4 text-center inside-carousel my-5">
              <p className="home-carousel-p  text-center mb-4 hide_cc">
                Ready to elevate your business to new heights?
              </p>
              <span className="home-carousel-span my-5  text-center inside-carousel">
                Experience the best in creative services with our all-inclusive
                solutions. We offer everything from 2D and 3D animation to
                motion graphics, VFX, and more, all at affordable rates and with
                rapid turnarounds. Let us do the heavy lifting while you sit
                back, relax, and enjoy the journey.
              </span>
            </div>
            <NavLink to="/animation-vfx" className="mb-md-4">
              <button className="button2">Checkout Our Services</button>
            </NavLink>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="d-flex flex-column justify-content-center align-items-center carousel_item_container  container">
            <h1 className="text-center home-carousel-h1 spacing_h1">
              Step Into Our{" "}
              <span className="home-carousel-h1-span">Creative Wonderland</span>
            </h1>

            <div className="my-4 text-center inside-carousel my-5">
              <p className="home-carousel-p  text-center mb-4 hide_cc">
                Ready to elevate your business to new heights?
              </p>
              <span className="home-carousel-span my-5  text-center  my-5 inside-carousel">
                Our team of artists, designers, and innovators collaborate
                seamlessly to bring ideas to life across various mediums. With
                cutting-edge technology and boundless imagination, we craft
                impactful experiences that inspire and engage. Join us in our
                journey of creativity!
              </span>
            </div>
            <NavLink to="/animation-page" className="mb-md-4">
              <button className="button2">
                Discover Our Creative Services
              </button>
            </NavLink>
          </div>
        </Carousel.Item>
      </Carousel>
    </header>
  );
};

export default Landingpage;
