import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NotFoundImage from "../Assets/Images/NotfoundImg.png";

const NotFound = () => {
  return (
    <section className="notFOund-section">
      <Container>
        <Row>
          <Col sm={12} lg={6} md={6}>
            <div className="notFound_img_wraper">
              <img src={NotFoundImage} alt="PageNotFound" />
            </div>
          </Col>
          <Col sm={12} lg={6} md={6} className="d-flex align-items-center">
            <div className="d-flex flex-column ps-lg-4 ">
              <h1 className="notfound_title mb-lg-4 text-center text-md-start">
                Oops!
              </h1>
              <span className="notfound-desc mb-3 text-center text-md-start">
                We can&apos;t seem to find the page you&apos;re looking for.
              </span>
              <span className="notfound-desc mt-lg-4 text-center text-md-start">
                Error code: 404
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NotFound;
