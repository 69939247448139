import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Banner from "./Banner";
import Services from "./Services";
import CauseStudey from "./CauseStudey";
import Typography from "./Typography";
import Contact from "./Contact";
import Footer from "./Footer";
import Layout from "./Layout";
import LogoSlider from "./LogoSlider";

// Components importes.

const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const textVariants = {
    initial: {
      y: 100,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <>
      <motion.div
        id="home"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <Layout />
        <Banner />
        <Services />
        <CauseStudey variants={textVariants} />
        <Typography textVariants={textVariants} />
        <LogoSlider direction={"left"} />
        <section className="contact-sectin-wraper py-xxl-5">
          <Contact textVariants={textVariants} />
        </section>
        <Footer textVariants={textVariants} />
      </motion.div>
    </>
  );
};

export default Home;
