import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";

const Typography = () => {
  return (
    <section className="py-5 typography-section d-flex align-items-center justify-content-center ">
      <Container>
        <Row>
          <Col className="overflow-hidden">
            <motion.h1
              className="typography-h1 text-md-center px-4 px-md-0 px-lg-0"
            >
              We&#39;ve crafted a plethora of
              <span className="typography-span"> Mobile & Web Apps</span> for
              our <span className="typography-span">amazing clients</span>, and
              the journey continues!
            </motion.h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Typography;
