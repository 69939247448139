import React from "react";
import { motion } from "framer-motion";

const Boxright = ({ icon1, icon2, title, description }) => {
  return (
    <motion.div
      className="cards box-right d-flex flex-column justify-content-center align-items-center align-items-lg-start"
      initial={{ scale: 0.5 }}
      whileInView={{ scale: 1 }}
      transition={{
        duration: 0.5,
        ease: "easeInOut",
      }}
      viewport={{ once: true }}
    >
      <div className="box-logo right-b d-flex mb-3">
        <div className="icons me-3">
          <img src={icon1} alt={title} />
        </div>
        {icon2 && (
          <div className="icons">
            <img src={icon2} alt={title} />
          </div>
        )}
      </div>
      <div className="cards-title   text-center text-lg-start  my- mb-3">
        {title}
      </div>
      <div className="cards-content  text-center text-lg-start ">
        {description}
      </div>
    </motion.div>
  );
};

export default Boxright;
