/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import Footer from "./Footer";
import FeedbackCard from "../ReusedComponentssssssssssssssssssss/Cards/FeedbackCard";
import mobileImage from "../Assets/Images/phone 1.png";
import tabImage from "../Assets/Images/tab 1.png";
import Designer from "../Assets/Images/Designer__21_-removebg-preview 1.png";
import BannerImage from "../Assets/Images/EVR_LOGO.png";
// import transition from "../Hooks/Transition";

const ProjectEstimate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("scrlling to top");
  }, []);

  const [cardHeight, setCardHeight] = useState("");

  const form = useRef();
  const feedBackRef = useRef(null);
  console.log(feedBackRef.current?.clientHeight);
  const [otherCheBoxActive, setOtherCheckbox] = useState(false);
  const [checkboxeValues, setCheckboxes] = useState({});
  const [checkBoxOtherValue, setCheckBoxOhter] = useState([]);
  const [feedBackCard, setFeedBackCard] = useState(false);
  const [userName, setUserName] = useState("");
  // .env
  const templateId = process.env.REACT_APP_TEMPLATE_ID_2;
  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const publickKey = process.env.REACT_APP_PUBLIC_KEY;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // chechk box name values
  const checkBoxData = [
    "Mobile App",
    "Web App",
    "UI/UX Design",
    "Server-side development",
    "E-commerce",
    "Quality Assuarance",
    "Branding",
    "Digital Markting",
    "2D Animation",
    "3D Animation",
  ];

  const budgetOptions = [
    "Not specific",
    "less than ₹50,000",
    "₹50,000- ₹1,000,000",
    "₹1,000,000 & above",
  ];

  // getting checkbox values
  const handleChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: checked,
    }));
  };

  // form submitting function demo
  const submitData = (data, event) => {
    event.preventDefault();
    setCardHeight(feedBackRef.current?.clientHeight);
    window.scrollTo(0, cardHeight);
    setUserName(data?.name);
    let formArray = [{}];
    const servicesArray = checkboxeValues;
    const formDatas = data;
    if (otherCheBoxActive) {
      formArray = { ...formDatas, checkBoxOtherValue };
      sendEmail();
    } else {
      formArray = { ...formDatas, ...servicesArray };
      sendEmail();
    }
    console.log("newArray", formArray);
  };

  const sendEmail = () => {
    // eslint-disable-next-line import/no-named-as-default-member
    emailjs
      .sendForm(serviceId, templateId, form.current, publickKey)
      .then(
        (result) => {
          console.log("SUCCESS!");
          console.log(result.text);
        },
        (err) => {
          console.log("FAILED...", err.text);
        }
      )
      .finally(setFeedBackCard(true));
  };

  return (
    <motion.section
      className="estimate_section_wraper mt-5 p-xxl-5"
      id="projectEstimate"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      {/* FORM SECTION */}
      <Container className=" mt-lg-5 pt-5 position-relative">
        <div className="mobile_logo">
          <img src={BannerImage} alt="EvolvierLogo" />
        </div>
        <Row>
          <Col sm={12} lg={5} xl={5}>
            <div className="estimate_first_col_wraper">
              <div>
                <h1 className="mb-0">Request project </h1>
                <h1 className="mb-4">estimate </h1>
                <span className="">
                  Thank you for considering Evolvier Technologies! Simply send
                  us your request for proposal, and we&rsquo;ll promptly provide you
                  with an estimate. Whether it&rsquo;s for web development, mobile
                  apps, software testing, animation, VFX, design, UI/UX, or any
                  other IT project you have in mind, we&rsquo;re here to assist you
                  every step of the way. Looking forward to working together!
                </span>
                {/* form section */}
                {!feedBackCard ? (
                  <Form
                    className="mt-4 mt-lg-5 d-flex flex-column"
                    onSubmit={handleSubmit(submitData)}
                    ref={form}
                  >
                    <div className="mb-3">
                      <input
                        className=" filed_inputs"
                        type="text"
                        name="from_name"
                        id=""
                        placeholder="Name*"
                        {...register("name", { required: true })}
                      />
                      <error className="">
                        {errors.name?.type === "required" &&
                          "Please enter your name"}
                      </error>
                    </div>
                    <input
                      className="mb-3 filed_inputs"
                      type="text"
                      name="company"
                      id=""
                      placeholder="Company"
                    />
                    <div className="mb-3">
                      <input
                        className=" filed_inputs"
                        type="email"
                        name="email"
                        id=""
                        placeholder="Email*"
                        {...register("email", {
                          required: true,
                          pattern: /^\S+@\S+$/i,
                        })}
                      />
                      <error className="">
                        {errors.email?.type === "required" &&
                          "Please enter your email"}
                        {errors.email?.type === "pattern" &&
                          "Enterd email is in wrong format"}
                      </error>
                    </div>
                    <div className="mb-5">
                      <input
                        className=" filed_inputs"
                        type="number"
                        name="phoneNumber"
                        id=""
                        placeholder="Phone Number*"
                        {...register("number", {
                          required: "Phone number is required",
                        })}
                      />
                      <error className="">
                        {errors.number?.type === "minLength" &&
                          "Enterd number is lessthan 10 digits"}
                        {errors.number?.type === "maxLength" &&
                          "Enterd number is more than 10 digits"}
                        {errors.number && (
                          <p className="mb-0">{errors.number.message}</p>
                        )}
                      </error>
                    </div>
                    <label className="mb-3 golbal_label mb-4">
                      What kind of services are you looking for?
                    </label>

                    {/* maping checkbox values */}
                    <div className="row">
                      {checkBoxData.map((list, i) => (
                        <Col
                          lg={4}
                          sm={4}
                          key={i}
                          className="grid-item mt-2 me-xxl-2 d-flex align-items-center justify-content-md-start justify-content-center mb-3"
                        >
                          <input
                            type="checkbox"
                            className="chekInputs me-2"
                            name={`${list}  service`}
                            id="checkBox"
                            onClick={() => {
                              window.dataLayer = window.dataLayer || [];
                              window.dataLayer.push({
                                event: "Estimate_checkbox_state",
                                Checkbox_State: `${list} checkbox clicked`,
                              });
                            }}
                            onChange={handleChange}
                          />
                          <label className="checkboxLabel">{list}</label>
                        </Col>
                      ))}
                      <div className="d-flex mt-3 align-items-center justify-content-between justify-content-md-start justify-content-lg-between other_checkbox">
                        <div className="d-flex me-md-3 align-items-center justify-content-center">
                          <input
                            type="checkbox"
                            className="chekInputs me-2"
                            name=""
                            id=""
                            onChange={() =>
                              setOtherCheckbox(!otherCheBoxActive)
                            }
                          />
                          <label className="checkboxLabel">Other</label>
                        </div>
                        {otherCheBoxActive && (
                          <motion.textarea
                            initial={{ y: 20 }}
                            whileInView={{ y: 0 }}
                            transition={{
                              duration: 0.5,
                              ease: "easeInOut",
                            }}
                            name="servceMessage"
                            value={checkBoxOtherValue}
                            placeholder="what you are looking for..."
                            id="textBOx"
                            className="p-2 otherTextBox"
                            onChange={(e) => setCheckBoxOhter(e.target.value)}
                          ></motion.textarea>
                        )}
                      </div>
                    </div>

                    <label className="golbal_label mb-3 mt-5">
                      Could you provide a brief overview of your requirements?
                    </label>

                    <textarea
                      className="p-estimate-textArea"
                      name="about"
                      id=""
                      cols="30"
                      rows="10"
                      {...register("message", { required: true })}
                    ></textarea>
                    <error className="mb-3">
                      {errors.message?.type === "required" &&
                        "Please enter your requirement"}
                    </error>

                    <label className="golbal_label mt-5 mb-3">
                      What is your expected budget?
                    </label>
                    <select
                      className="estimate_select"
                      id="price"
                      name="budget"
                      {...register("price", {
                        required: "This field is required",
                      })}
                    >
                      {budgetOptions.map((data, i) => {
                        return (
                          <option
                            key={i}
                            className="budget-sec"
                            onClick={() => {
                              window.dataLayer = window.dataLayer || [];
                              window.dataLayer.push({
                                event: "Estimate_Budget",
                                Url_Action: `${data} Budget selected`,
                              });
                            }}
                          >
                            {data}
                          </option>
                        );
                      })}
                    </select>
                    <error className="mb-3">
                      {errors.message?.type === "required" &&
                        "Please set the budget"}
                    </error>
                    <button
                      type="submit"
                      className="estimate_submit_btn mt-4"
                      onClick={() => {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                          event: "Estimate_submit_button",
                          Url_Action: `Submit button clicked`,
                        });
                      }}
                    >
                      Send
                    </button>
                  </Form>
                ) : (
                  <div ref={feedBackRef} className="mt-5">
                    <FeedbackCard userName={userName} />
                  </div>
                )}
              </div>
            </div>
          </Col>

          <Col sm={12} lg={7} xl={7} className="hide-estimate_mobile">
            <div className="estimate_image_main position-relative h-100">
              <motion.div
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                viewport={{ once: true }}
                className="inside_round_box position-absolute"
              ></motion.div>

              <motion.img
                initial={{ y: 50 }}
                whileInView={{ y: 0 }}
                transition={{ duration: 1, ease: "easeInOut" }}
                viewport={{ once: true }}
                src={mobileImage}
                className="position-absolute img-1"
                alt="MobileImage"
              />
              <motion.img
                initial={{ x: 50 }}
                whileInView={{ x: 0 }}
                transition={{ duration: 1, ease: "easeInOut" }}
                viewport={{ once: true }}
                src={tabImage}
                className="position-absolute img-2"
                alt="TabImage"
              />
              <motion.img
                initial={{ x: 50 }}
                whileInView={{ x: 0 }}
                transition={{ duration: 1, ease: "easeInOut" }}
                viewport={{ once: true }}
                src={Designer}
                alt="designerImage"
                className="position-absolute img-3"
              />
            </div>
          </Col>
        </Row>
      </Container>

      {/* footer section */}
      <Footer />
    </motion.section>
  );
};

export default ProjectEstimate;
