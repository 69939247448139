import React from "react";
import { motion } from "framer-motion";

const Amvfxcard = ({ title, desc, textDirection }) => {
  return (
    <motion.div
      className={`am-vfx-card ${textDirection}`}
      initial={{ scale: 0.5 }}
      whileInView={{ scale: 1 }}
      transition={{
        duration: 0.5,
        ease: "easeInOut",
      }}
      viewport={{ once: true }}
    >
      <h5>{title}</h5>
      <span>{desc}</span>
    </motion.div>
  );
};

export default Amvfxcard;
