import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import L11 from "../Assets/Images/L11.png";
import L12 from "../Assets/Images/L12.png";
import L13 from "../Assets/Images/L13.png";
import { motion } from "framer-motion";

const LeftcardOne = ({
  title,
  subtitle,
  content1,
  content2,
  content3,
  imgPath,
  isShow,
}) => {
  const refOne = useRef(null);

  return (
    <Container
      className="pt-5 py-md-5 mt-5 caus-container d-flex align-items-center justify-content-center flex-column"
      ref={refOne}
    >
      <Row className="position-relative flex-column-reverse flex-lg-row p-2 p-lg-0">
        <Col sm={12} md={12} lg={6}>
          <div className="d-flex flex-column align-items-start justify-content-center">
            <div className="content-wraper text-md-center text-lg-start overflow-hidden mb-lg-3 mt-3 mt-md-5 mt-lg-0">
              <motion.h2 className="content-tile mb-2">{title}</motion.h2>
              <motion.p className="content-subTitle text-lg-start mb-3 text-md-center">
                {subtitle}
              </motion.p>
            </div>
            <motion.div className="contents-span-wraper d-fle flex-column">
              <p className="contents mb-0 ">{content1}</p>
              <p className="contents my-4 ">{content2}</p>
              <p className="contents mb-0 ">{content3}</p>
            </motion.div>
          </div>
        </Col>
        <Col sm={12} md={12} lg={6} className="mb-3">
          <motion.div className="img-wraper-leftOne">
            <img id="left-img" src={imgPath} alt={title} />
          </motion.div>
          <div className="img-wraper-2 d-flex justify-content-between py-1 py-lg-3 p-xxl-2">
            <motion.div className="img-inside-wraper">
              <img src={L11} alt="" />
            </motion.div>
            <motion.div className="img-inside-wraper">
              <img src={L12} alt="" />
            </motion.div>
            <motion.div className="img-inside-wraper">
              <img src={L13} alt="" />
            </motion.div>
          </div>
        </Col>
      </Row>
      {isShow ? <div className="custom-dotted-div"></div> : ""}
    </Container>
  );
};

export default LeftcardOne;
