import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";

const RightCard = ({
  title,
  subtitle,
  content1,
  content2,
  content3,
  content4,
  imgPath,
}) => {
  const refOne = useRef(null);

  return (
    <Container
      className="pt-5 mt-5 py-md-5 caus-container d-flex align-items-center justify-content-center flex-column"
      ref={refOne}
    >
      <Row className="position-relative p-2 p-lg-0">
        <Col sm={12} md={12} lg={6} xl={6} className="mb-4 ">
          <motion.div
            className="img-wraper"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <motion.img src={imgPath} alt={title} />
          </motion.div>
        </Col>
        <Col sm={12} md={12} lg={6} xl={6} className="mb-3 ps-xl-5">
          <div>
            <div className="content-wraper text-md-center overflow-hidden mb-lg-3 ">
              <motion.h2 className="content-tile text-lg-start mb-2">{title}</motion.h2>
              {subtitle && (
                <motion.p className="content-subTitle text-md-center text-lg-start mb-3">
                  {subtitle}
                </motion.p>
              )}
            </div>
            <motion.div className="contents-span-wraper d-fle flex-column">
              <p className="contents mb-0">{content1}</p>
              <p className="contents my-2 my-md-3 my-lg-3">{content2}</p>
              <p className="contents mb-0">{content3}</p>
              {content4 && <p className="contents mb-0">{content4}</p>}
            </motion.div>
          </div>
        </Col>
      </Row>
      <div className="custom-dotted-div"></div>
    </Container>
  );
};

export default RightCard;
