import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import FeedbackCard from "../ReusedComponentssssssssssssssssssss/Cards/FeedbackCard";

const Contact = () => {
  const [feedbackCard, setFeedbackCard] = useState(false);
  const [userName, setUserNamae] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  //main  function..
  const onSubmits = (data, event) => {
    event.preventDefault();
    if (data.name.type === "required") {
      console.log("submisssion faild");
    } else {
      emailjs
        .sendForm(serviceId, templateId, formRef.current, publickKey)
        .then(
          (result) => {
            console.log(formRef.current);
            console.log(result.text);
            setFeedbackCard(true);
          },
          (err) => {
            console.log(err.text);
          }
        )
        .finally(setFeedbackCard(true), setUserNamae(data.name));
    }
  };

  const formRef = useRef(null);
  //    ///    ////   ////   ///   ///
  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const publickKey = process.env.REACT_APP_PUBLIC_KEY;

  return (
    <motion.section className="contact-wraper py-5  py-md-5 " id="contact">
      <Container className="margin-container">
        <Row>
          <Col
            sm={12}
            md={12}
            lg={6}
            className="padding-mobile-c px-md-5 px-lg-0 d-flex flex-column align-items-start align-items-md-center justify-content-center overflow-hidden mb-md-4 ps-lg-2 mb-lg-0"
          >
            <motion.div>
              <div className="px- px-md-0 add_spacing">
                <motion.h1 className="contact-h1 text-md-center text-lg-start mb-lg-4 mb-2 mb-md-3">
                  Ready to Supercharge Your Business?
                </motion.h1>
                <motion.h2 className="contact-h2 text-md-center text-lg-start mb-4 mb-lg-0">
                  We are listening...
                </motion.h2>
              </div>
            </motion.div>
          </Col>
          <Col sm={12} md={12} lg={6}>
            {feedbackCard ? (
              <FeedbackCard userName={userName} />
            ) : (
              <motion.form onSubmit={handleSubmit(onSubmits)} ref={formRef}>
                <div>
                  <div className="d-xxl-flex flex-xxl-column align-items-xxl-end">
                    <div className="span-wraper mb-3 ps-5 p-xxl-0">
                      <span className="span-label">Get in touch</span>
                    </div>
                    <div className="input-grups d-flex flex-column align-items-center jusitfy-content-center">
                      <motion.input
                        className="form-inputs   ps-3"
                        type="text"
                        name="from_name"
                        id=""
                        placeholder="Your Name*"
                        {...register("name", { required: true })}
                      />
                      <error className="mb-3">
                        {errors.name?.type === "required" &&
                          "Please enter your name"}
                      </error>
                      {/* <label className="optional-label">(Optional)</label> */}
                      <motion.input
                        className="form-inputs mb-3 ps-3"
                        type="text"
                        name="company"
                        id=""
                        placeholder="Company"
                      />
                      <motion.input
                        className="form-inputs  ps-3"
                        type="number"
                        name="phoneNumber"
                        id=""
                        placeholder="Phone Number*"
                        {...register("number", {
                          minLength: 10,
                          maxLength: 12,
                        })}
                        {...register("number", {
                          required: "Phone number is required",
                        })}
                      />
                      <error className="mb-3">
                        {errors.number?.type === "minLength" &&
                          "Enterd number is lessthan 10 digits"}
                        {errors.number?.type === "maxLength" &&
                          "Enterd number is more than 10 digits"}
                        {errors.number && (
                          <p className="mb-0">{errors.number.message}</p>
                        )}
                      </error>
                      <motion.input
                        className="form-inputs  ps-3"
                        type="email"
                        name="email"
                        id=""
                        placeholder="Email*"
                        {...register("email", {
                          required: true,
                          pattern: /^\S+@\S+$/i,
                        })}
                      />
                      <error className="mb-3">
                        {errors.email?.type === "required" &&
                          "Please enter your email"}
                        {errors.email?.type === "pattern" &&
                          "Enterd email is in wrong format"}
                      </error>
                      <motion.textarea
                        className="ps-3 md-text"
                        name="message"
                        id=""
                        cols="30"
                        rows="10"
                        placeholder="Message*"
                        {...register("message", { required: true })}
                      ></motion.textarea>
                      <error className="mb-3">
                        {errors.message?.type === "required" &&
                          "Please enter your feedback"}
                      </error>

                      <motion.button
                        id="formSubmit"
                        className="button  mt-3"
                        onClick={() => {
                          handleSubmit(onSubmits);
                          window.dataLayer = window.dataLayer || [];
                          window.dataLayer.push({
                            event: "FormSubmit_Button_Click",
                            Url_Action: "Form submit button clicked",
                          });
                        }}
                      >
                        Send
                      </motion.button>
                    </div>
                  </div>
                </div>
              </motion.form>
            )}
          </Col>
        </Row>
      </Container>
    </motion.section>
  );
};

export default Contact;
