import React from "react";

const Cards = ({ icon1, icon2, title, description }) => {
  return (
    <div className="cards  d-flex flex-column align-items-start justify-content-evenly   py-4 ps-4 ">
      <div className="cards-icon d-flex justify-content-center">
        <div className="icons">
          <img src={icon1} alt={title} />
        </div>
        {icon2 && (
          <div className="icons">
            <img src={icon2} alt={title} />
          </div>
        )}
      </div>
      <div className="cards-title my-4">{title}</div>
      <div className="cards-content pe-md-2">{description}</div>
    </div>
  );
};

export default Cards;
