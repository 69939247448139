import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Contact from "../../Components/Contact";

const ContactModal = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <button className="modal-btn" onClick={() => setShow(true)}>
        Contact Us
      </button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton className="bottom-remove">
          <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Contact />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactModal;
