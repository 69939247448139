// BANNER SECTION DATAS..
export const BannerData = [
  {
    title1: "Wow Service!",
    title2:
      "Custom solutions, hassle-free. Sit back, relax, and leave it to us.",
  },
  {
    title1: "Ally Crew: Your Squad",
    title2:
      "Experience meets innovation! Let's build better together with our expertise.",
  },
  {
    title1: "Smart IT Solutions",
    title2:
      "Innovative, tailored IT solutions crafted just for you. Let's make your business shine.",
  },
];

export const towDAnimation = [
  {
    title: "Concept Art and Storyboarding",
    desc: "Creating visual concepts and storyboards to plan and outline the animation sequences.",
  },
  {
    title: "Character Design and Development",
    desc: "Designing characters, including their appearance, movements, and personalities.",
  },
  {
    title: "Background and Environment Design",
    desc: "Crafting detailed backgrounds and environments for the animation.",
  },
  {
    title: "2D Animation Production",
    desc: "Creating animated sequences using techniques like frame-by-frame animation, cutout animation, and vector animation.",
  },
  {
    title: "Motion Graphics",
    desc: "Designing animated graphics for use in videos, advertisements, and user interfaces.",
  },
  {
    title: "Visual Effects (VFX)",
    desc: "Adding 2D visual effects to live-action footage or animation.",
  },
  {
    title: "Illustration",
    desc: "Providing high-quality illustrations for various purposes, such as books, advertisements, and games.",
  },
  {
    title: "Explainer Videos",
    desc: "Producing short, informative videos that explain concepts, products, or services.",
  },
  {
    title: "Title Sequences and Credits",
    desc: "Designing animated opening titles and end credits for films, TV shows, and other media.",
  },
  {
    title: "Animation for Apps and Games",
    desc: "Creating animations for mobile apps and video games, including sprites and UI animations.",
  },
];

export const threeDAnimation = [
  {
    title: "3D Modeling",
    desc: "Creating three-dimensional models of characters, objects, and environments.",
  },
  {
    title: "Texturing",
    desc: "Applying textures to 3D models to give them color, surface detail, and realism.",
  },
  {
    title: "Rigging",
    desc: "Building a digital skeleton for 3D models so they can be animated.",
  },
  {
    title: "3D Animation",
    desc: "Creating animated sequences, including character movements, camera motion, and more.",
  },
  {
    title: "Lighting and Shading",
    desc: "5. Designing lighting setups to enhance the realism and mood of 3D scenes.",
  },
  {
    title: "Rendering",
    desc: "Converting 3D models and animations into final images or video sequences.",
  },
  {
    title: "Visual Effects (VFX)",
    desc: "Adding 3D visual effects to live-action footage or fully 3D animations.",
  },
  {
    title: "Motion Capture",
    desc: "Adding 3D visual effects to live-action footage or fully 3D animations.",
  },
  {
    title: "Virtual Reality (VR) and Augmented Reality (AR)",
    desc: "Creating immersive 3D content for VR and AR applications.",
  },
  {
    title: "Architectural Visualization",
    desc: "Producing 3D visualizations of buildings and interior designs for architects and real estate developers.",
  },
  {
    title: "Product Visualization",
    desc: "Creating detailed 3D models and animations of products for marketing and presentations.",
  },
  {
    title: "Medical and Scientific Animation",
    desc: "Developing animations to illustrate medical, biological, or scientific concepts.",
  },
];

export const amfxAddditonlData1 = [
  {
    title: "Scriptwriting and Story Development",
    desc: "Crafting compelling narratives and scripts for animation projects.",
  },
  {
    title: "Post-Production",
    desc: "Editing and refining animations, including color correction, compositing, and final touches.",
  },
];

export const amdfAddData2 = [
  {
    title: "Voice Acting and Sound Design",
    desc: "Providing voiceover talent and creating sound effects and music to accompany animations.",
  },
  {
    title: "Consultation and Training",
    desc: "Offering expertise and training for individuals and companies looking to improve their animation capabilities.",
  },
];

// ABOUT US PAGE
export const aboutData = [
  {
    bg: "#FFC52F",
    title: "Focused Teams",
    desc: "We'll put together a dedicated team of experts just for your project. They'll focus solely on building an app that perfectly meets your needs.",
  },
  {
    bg: "#2BECBE",
    title: "Expertise at Your Beck and Call",
    desc: "The development team is here to offer you comprehensive development services from start to finish. Alternatively, we can jump in at any stage of your project with as many experts as necessary.",
  },
  {
    bg: "#EC2BB6",
    title: "Steering the Ship",
    desc: "During development, you'll seamlessly integrate into our team, with the opportunity to provide real-time guidance to our developers.",
  },
];

export const aboutGuideDataOne = [
  {
    title: "Discovery",
    desc: "We start by analyzing your requirements and expectations, taking into account your unique needs. From there, we develop an initial plan for your review and feedback.",
  },
  {
    title: "Crafting the Blueprint",
    desc: "We carefully go through all the necessary steps: confirming features with you, agreeing on timelines, conducting feasibility analysis, and finalizing the design blueprint.",
  },
  {
    title: "Quality Assurance",
    desc: "We adhere to test-driven development, conducting thorough functional and security testing to uphold the software's highest standards.",
  },
  {
    title: "Support & Maintenance",
    desc: "We ensure our software is easy for your team to maintain, and we provide ongoing support and maintenance services for all kinds of projects, especially larger or more complex ones.",
  },
];

export const aboutGuideDataTwo = [
  {
    title: "Design UI/UX",
    desc: "We fine-tune every design detail, from architecture to user interface, before presenting it for your approval.",
  },
  {
    title: "Teamwork-Development",
    desc: "Our team of developers, graphic designers, and project managers works together to craft efficient, top-notch software that meets your exact specifications.",
  },
  {
    title: "Deployment",
    desc: "Our team of developers, graphic designers, and project managers works together to craft efficient, top-notch software that meets your exact specifications.",
  },
];

// DESING PAGE DATA FOR MAPPING...
export const designWorkData = [
  {
    title: "Print Media/Publications",
    desc: "Developing visual concepts and layouts to strategize and outline the content presentation in print media and publications.",
  },
  {
    title: "UI/UX Design",
    desc: "We prioritize user experience through UI/UX design, incorporating case studies, infographics, and design elements for a remarkable system. With our expertise, captivating your audience is effortless.",
  },
  {
    title: "Social Media Designs",
    desc: "Have a social media vision? We'll bring it to life! Let Evolvier handle the details. Our team tailors strategies to elevate your brand's story. Let's create captivating social media designs together!",
  },
  {
    title: "Event Design",
    desc: "We specialize in crafting every detail of your event, from nameboards and direction boards to flyers, cards, stalls, products, and backdrops, ensuring excellence in every aspect with our expertise.",
  },
];

export const designWorkData2 = [
  {
    title: "Web Design",
    desc: "We creates customized solutions for your web design needs, ensuring top-notch quality every step of the way.",
  },
  {
    title: "Branding & Identity",
    desc: "We take care of the details, crafting tailored strategies to reveal your brand's essence and elevate its story. Let's turn your dreams into a branding masterpiece!",
  },
  {
    title: "Marketing Collateral",
    desc: "Elevate your marketing materials with our expertise! Let's collaborate to craft impactful collaterals that resonate with your audience.",
  },
];

// FOOTER DTATA
export const footerAnimationData = [
  "Concept Art and Storyboarding",
  "3D Modeling",
  "Character Design and Development",
  "Texturing",
  "Rigging",
  "2D Animation Production",
  "3D Animation",
  "Motion Graphics",
  "Visual Effects (VFX)",
  "Post-Production",
];

export const footerDesignData = [
  "Print Media/Publications",
  "Web Design",
  "UI/UX Design",
  "Branding & Identity",
  "Social Media Designs",
  "Marketing Collateral",
  "Event Design",
];
