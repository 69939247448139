import React, { useEffect, useState } from "react";
import "./App.css";
// bootstrap styles link..
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
// Components importes.
import Services from "./Components/Services";
import Contact from "./Components/Contact";
import Home from "./Components/Home";
import LocomotiveScroll from "locomotive-scroll";
import ProjectEstimate from "./Components/ProjectEstimate";
import AnimationVfx from "./Components/AnimationVfx";
import AboutUs from "./Components/AboutUs";
import DesignPage from "./Components/DesignPage";
import NotFound from "./Components/NotFound";
import NewServices from "./Components/NewServices";
// import ScrollToTop from "./Hooks/ScrollToTop ";
import Navmenu from "./Components/Navmenu";
import Preloader from "./Hooks/Preloader";
// import Preloader from "./Hooks/Preloader";

function App() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const locomotiveScroll = new LocomotiveScroll({
    lenisOptions: {
      wrapper: window,
      content: document.documentElement,
      lerp: 0.1,
      duration: 1.2,
      orientation: "vertical",
      gestureOrientation: "vertical",
      smoothWheel: true,
      smoothTouch: false,
      wheelMultiplier: 1,
      touchMultiplier: 2,
      normalizeWheel: true,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
    },
  });
  console.log(locomotiveScroll);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 1500);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!isLoading ? (
        <Preloader />
      ) : (
        <>
          {/* <ScrollToTop /> */}
          <Navmenu />
          <AnimatePresence initial={true} mode="wait">
            <Routes location={location} key={location.pathname}>
              <Route index path="/" element={<Home />} />
              <Route path="/service" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/project-estimate" element={<ProjectEstimate />} />
              <Route path="/animation-page" element={<DesignPage />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/animation-vfx" element={<AnimationVfx />} />
              <Route path="/services" element={<NewServices />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AnimatePresence>
        </>
      )}
    </>
  );
}

export default App;
