import React from "react";
// import Navmenu from "./Navmenu";
import Landingpage from "./Landingpage";

const Layout = () => {
  return (
    <main className="position-relative layout">
      {/* <Navmenu /> */}
      <Landingpage />
    </main>
  );
};

export default Layout;
