/* eslint-disable import/no-unresolved */
import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
// eslint-disable-next-line import/no-unresolved
import RightCard from "../ReusedComponentssssssssssssssssssss/Caustudy/RightCard";
import LeftCard from "../ReusedComponentssssssssssssssssssss/Caustudy/LeftCard";

// assets imports...
import caustudyImg1 from "../Assets/Gifs/mobile_app.gif";
import caustudyImg2 from "../Assets/Gifs/foor_order.gif";
import caustudyImg3 from "../Assets/Gifs/green_earth.gif";
import caustudyImg4 from "../Assets/Images/Rightside2.png";
import LeftcardOne from "./LeftcardOne";

import { motion } from "framer-motion";

// titles...
const title1 = "E-commerce";
const title2 = "Restaurant Management";
const title3 = "Sustainable Solutions for Tomorrow";
const title4 = "Employee Management";
// sub-titles...
const subtitle1 = "Food, Fashion, Grocery, Fitness, Medicine and more";
const subtitle2 = "A comprehensive restaurant solution.";

const CauseStudey = () => {
  const headRef = useRef(null);
  // text variants animation
  const textVariants = {
    initial: {
      y: 100,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <main className="mt-md-4">
      <Container className="pt-5 mt-xl-5 mb-md-5 pb-md-5">
        <Row>
          <Col lg={12} className="text-center mt-xxl-5 " ref={headRef}>
            <div className="overflow-hidden">
              <motion.h1 className="main-headlines ">Click. Solved.</motion.h1>
            </div>
            <span className="cotent-typography">
              Discover our tailored solutions for clients
            </span>
          </Col>
        </Row>
      </Container>
      {/* causestudy rendering */}
      <section className="pt-5 pt-md-0 pt-lg-0">
        <RightCard
          imgPath={caustudyImg1}
          title={title1}
          subtitle={subtitle1}
          content1={
            "In the dynamic world of e-commerce, each click feels like discovering a hidden gem in a digital treasure trove. It's not merely shopping; it's the exhilaration of finding that perfect item. Our journey in online retail has been an adventure, filled with diverse challenges. From navigating complex payment integrations to tailoring product suggestions and managing stock efficiently, we've encountered and conquered them all."
          }
          content2={
            "Rest assured, every challenge comes with a solution. With our expertise, creativity, and determination, we've seamlessly integrated payment methods, personalized shopping experiences, optimized inventory management, and ensured robust data security."
          }
          content3={
            "Together with our valued clients, we've turned their e-commerce dreams into reality, helping them flourish in the ever-changing digital landscape. Are you ready to take your online store to new heights? Let's make your e-commerce aspirations a success story!"
          }
          isShow
          textVariants={textVariants}
        />
        {/* <LeftCard imgPath={caustudyImg2} title={title2} subtitle={subtitle2} content={content2} isShow/> */}
        <LeftcardOne
          imgPath={caustudyImg2}
          title={title2}
          subtitle={subtitle2}
          content1={
            "Step into the fast-paced world of restaurant management, where every order, every dish, and every payment is meticulously handled to ensure a seamless dining experience. At our core, we're problem solvers, and we've taken on the challenge of developing comprehensive restaurant management systems for our valued clients."
          }
          content2={
            "From streamlining order-taking processes to optimizing kitchen operations, managing stock inventory, and facilitating secure payment transactions, we've left no stone unturned. We successfully solved our clients' biggest problems, making their business operations easier and more efficient."
          }
          content3={
            "We work very closely with the client to understand the business flow and provide more reliable solutions for our clients. With our expertise in software development and a deep understanding of the hospitality industry, we've crafted tailored solutions to address the unique needs of each restaurant."
          }
          isShow
          textVariants={textVariants}
        />
        <RightCard
          imgPath={caustudyImg3}
          title={title3}
          content1={
            "For years, we've been partnering with clients dedicated to sustainable solutions, tirelessly developing strategies to realize their goals. Together, we've pioneered innovative approaches to environmental stewardship, empowering our clients to lead the charge towards a greener future."
          }
          content2={
            "From designing systems for efficient recycling management and promoting the reuse of bags to implementing cutting-edge technology for managing bag kiosks and orchestrating seamless business operations through a single admin panel, we've been at the forefront of sustainability."
          }
          content3={
            "Additionally, we've facilitated the collection of plastic waste from diverse regions, ensuring careful disposal and minimizing environmental impact."
          }
          content4={
            "Collaborating with clients who share a profound vision for a better world is nothing short of exhilarating, and we're eager to continue our journey towards making our planet a healthier, more sustainable place to call home."
          }
          textVariants={textVariants}
        />
        <LeftCard
          imgPath={caustudyImg4}
          title={title4}
          content1={
            "A solution is provided to closely monitor the performance of corporate employees. Through the system, clients can track employee activities, including their locations and interactions, using a mobile app specifically designed for job-related tasks."
          }
          content2={
            "Identifying and addressing loopholes in the system is key to improving employee performance. The solution helps clients pinpoint areas for improvement, allowing them to resolve issues efficiently and effectively."
          }
          content3={
            "Implementing employee management systems within their ecosystem has helped clients overcome the challenges posed by a large number of unmanaged employees. By streamlining processes and enhancing organization, the systems alleviate headaches and improve overall efficiency."
          }
          textVariants={textVariants}
        />
      </section>
    </main>
  );
};

export default CauseStudey;
