import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Desing from "../Assets/Images/DesignPageImg.png";
import BannerImage from "../Assets/Images/EVR_LOGO.png";
import { designWorkData, designWorkData2 } from "../Assets/data/Contents";
import Amvfxcard from "../ReusedComponentssssssssssssssssssss/Cards/Amvfxcard";
import Contact from "./Contact";
import Footer from "./Footer";
import { motion } from "framer-motion";

const DesignPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.section
      className="designPage-section"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <Container className="defult-mt text-center mt-5 mt-lg-5 pt-5 pt-lg-5 d-flex flex-column align-items-center">
        <div className="mobile_logo">
          <img src={BannerImage} alt="EvolvierLogo" />
        </div>
        <h1 className="abt-h1 mt-lg-5 mb-5">
          Superior Design & <br />
          Concept Work
        </h1>
        <p className="abut-p design-p">
          We&apos;re fueled by a passion for enhancement, a knack for
          exceptional design, and a deep desire to make an impact across print,
          web, digital, and event platforms.
        </p>
        <motion.div
          initial={{ y: 50 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          className="amvfx-img-wrapper mt-4 mb-lg-4"
        >
          <img src={Desing} alt="Design page " />
        </motion.div>
      </Container>

      <Container className=" designpage-container mt-5 mb-5">
        <Row className="justify-content-between position-relative">
          {/* first column */}
          <Col lg={5} className="d-flex align-items-center flex-column">
            <Row>
              {designWorkData.map((data, i) => {
                return (
                  <Col
                    key={i}
                    md={6}
                    lg={12}
                    className="d-flex justify-content-center"
                  >
                    <Amvfxcard key={i} {...data} textDirection="text-lg-end" />
                  </Col>
                );
              })}
            </Row>
          </Col>

          {/* second column */}
          <Col lg={5} className="d-flex align-items-center flex-column">
            <Row>
              {designWorkData2.map((data, i) => {
                return (
                  <Col
                    key={i}
                    md={6}
                    lg={12}
                    className="d-flex justify-content-center"
                  >
                    <Amvfxcard
                      key={i}
                      {...data}
                      textDirection="text-lg-start"
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>

          {/* svg */}
          <div className="designPage-svg-wraper position-absolute">
            <svg
              width="5"
              height="711"
              viewBox="0 0 5 711"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="2.5"
                y1="3.47852"
                x2="2.50003"
                y2="708.244"
                stroke="#E1E1E1"
                strokeWidth="5"
                strokeLinecap="round"
                strokeDasharray="0 10"
              />
            </svg>
          </div>
        </Row>
      </Container>

      <div className="wraper-contact">
        <Contact />
      </div>
      <Footer />
    </motion.section>
  );
};

export default DesignPage;
