import React from "react";
import { motion } from "framer-motion";

const Boxleft = ({ icon1, icon2, title, description }) => {
  return (
    <motion.div
      className=" cards box-left d-flex flex-column justify-content-center align-items-center align-items-lg-end"
      initial={{ scale: 0.5 }}
      whileInView={{ scale: 1 }}
      transition={{
        duration: 0.5,
        ease: "easeInOut",
      }}
      viewport={{ once: true }}
    >
      <div className="box-logo left-b d-flex mb-3">
        <div className="icons ">
          <img src={icon1} alt="" />
        </div>
        {icon2 && (
          <div className="icons ms-3">
            <img src={icon2} alt={title} />
          </div>
        )}
      </div>
      <div className="cards-title text-lg-end text-center my- mb-3">
        {title}
      </div>
      <div className="cards-content text-lg-end text-center">{description}</div>
    </motion.div>
  );
};

export default Boxleft;
