import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";

const LeftCard = ({
  title,
  subtitle,
  content1,
  content2,
  content3,
  imgPath,
  isShow,
}) => {
  const refOne = useRef(null);

  return (
    <Container
      className="pt-5 mt-5 caus-container d-flex align-items-center justify-content-center flex-column"
      ref={refOne}
    >
      <Row className="position-relative flex-column-reverse flex-lg-row p-2 p-lg-0">
        <Col sm={12} md={12} lg={6} xl={6}>
          <div className="d-flex flex-column align-items-start justify-content-center">
            <div className="content-wraper text-md-center text-lg-start overflow-hidden">
              <motion.h2 className="content-tile">{title}</motion.h2>
              {subtitle && (
                <motion.p className="content-subTitle">{subtitle}</motion.p>
              )}
            </div>
            <motion.div className="contents-span-wraper d-fle flex-column mb-5">
              <p className="contents mb-0">{content1}</p>
              <p className="contents my-2 my-lg-4 my-md-3">{content2}</p>
              <p className="contents mb-0">{content3}</p>
            </motion.div>
          </div>
        </Col>
        <Col sm={12} md={12} lg={6} xl={6} className="mb-4">
          <motion.div
            className="img-wraper"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ ease: "easeInOut", duration: 0.7 }}
            viewport={{ once: true }}
          >
            <img id="left-img" src={imgPath} alt={title} />
          </motion.div>
        </Col>
      </Row>
      {isShow ? <div className="custom-dotted-div"></div> : ""}
    </Container>
  );
};

export default LeftCard;
