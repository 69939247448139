import React from "react";
import { motion } from "framer-motion";

const AboutCard = ({ title, desc, borderValue }) => {
  return (
    <motion.div
       className="p-4 abt-card " style={{ borderRadius: `${borderValue}` }}
        initial={{scale:0.6}}
        whileInView={{scale:1}}
        transition={{duration:0.6,ease:"easeIn"}}
        whileHover={{boxShadow:"0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)"}}
        viewport={{once:true}} 
      >
      <h4 className="abt-card-h4">{title}</h4>
      <span className="abt-card-span">{desc}</span>
    </motion.div>
  );
};

export default AboutCard;
