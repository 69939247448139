import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
//components import
import Cards from "../ReusedComponentssssssssssssssssssss/Cards/Cards";

// slick imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// FRAMER MOTION..
import { motion } from "framer-motion";
// carousel images assets..
import cad1 from "../Assets/Images/CarouselImg/cad-1.png";
import cad2 from "../Assets/Images/CarouselImg/cad-2.png";
import playstore from "../Assets/Images/CarouselImg/Play_Store 1.png";
import appStore from "../Assets/Images/CarouselImg/app-store 1.png";
import Network from "../Assets/Images/CarouselImg/internet 1.png";
import layer from "../Assets/Images/CarouselImg/due.png";
import monitering from "../Assets/Images/CarouselImg/monitoring 1.png";
import database from "../Assets/Images/CarouselImg/database 1.png";
import server from "../Assets/Images/CarouselImg/server 1.png";
import cloud from "../Assets/Images/CarouselImg/cloud 1.png";
import social from "../Assets/Images/CarouselImg/social-media 1.png";
import idea from "../Assets/Images/CarouselImg/think 1.png";
import social2 from "../Assets/Images/CarouselImg/social-media 2.png";
import think2 from "../Assets/Images/CarouselImg/think 2.png";
// components import
import Boxright from "../ReusedComponentssssssssssssssssssss/Boxcard/Boxright";
import Boxleft from "../ReusedComponentssssssssssssssssssss/Boxcard/Boxleft";

const Services = () => {
  const refElelm = useRef(null);
  const spanRef = useRef(null);

  // REUSABLE CARD DATAS..
  const title = "Cross Platform App Development";
  const title2 = "Native App Development";
  const title3 = "Web App Development";
  const title4 = "Design & User Experience";
  const title5 = "Maintenance & App monitoring";
  const title6 = "Database solutions";
  const title7 = "Backend and API development";
  const title8 = "Cloud Service";
  const title9 = "Digital Marketing";
  const title10 = "End to End Solutions";
  const title11 = "Quality Assurance";
  const title12 = "Branding";

  const desc1 =
    "Expand your reach, delight your audience! Our multi-platform release ensures broader access for your users, unlocking new opportunities for engagement and growth";
  const desc2 =
    "We're your go-to for Native app development. Our team dives deep into feasibility and impact studies to craft strategic solutions just for you.";
  const desc3 =
    "From concept to completion, we've got you covered! Our team meticulously crafts tailored solutions for your Web App needs. With diverse expertise, expect nothing but the best.";
  const desc4 =
    "We prioritize user experience, using case studies, infographics, and design elements to make your system truly remarkable. With our expertise, we'll captivate your audience effortlessly.";
  const desc5 =
    "Let us take the wheel! With expert maintenance solutions tailored to your needs, we guarantee top-notch security, availability, and reliability. Your operations, our priority.";
  const desc6 =
    "Unlock your data's potential with our seamless database solutions – secure, accessible, and always performing at their best!";
  const desc7 =
    "Let us take the wheel! With expert maintenance solutions tailored to your needs, we guarantee top-notch security, availability, and reliability. Your operations, our priority.";
  const desc8 =
    "Let us handle the complexity! Evolvier offers top-notch backend solutions, expertly scaling to meet your traffic demands. Put your development worries to rest with us by your side.";
  const desc9 =
    "Navigate the digital landscape with ease! Evolvier specializes in comprehensive digital marketing solutions, ready to scale alongside your brand's growth. Trust us to carry your marketing burdens while you focus on soaring to new heights.";
  const desc10 =
    "Got an idea? We've got your back! Let Evolvier handle the rest. Our team customizes plans to unlock your full potential and add value to your journey. Let's make your vision a reality!";
  const desc11 =
    "Opt for our Software Testing and QA Services to guarantee flawless performance of your web and mobile applications through comprehensive quality assurance and testing procedures.";
  const desc12 =
    "Have a vision? We're here to bring it to life! Trust in BrandForge to  take care of the details. Our dedicated team tailors strategies to  unleash your brand's true essence and elevate your narrative. Let's turn  your dreams into a brand masterpiece!!";

  // DATAS FOR CAROUSEL IN  MOBILE VIEW
  const CarouselData = [
    {
      icon1: cad2,
      icon2: cad1,
      color: "#fff",
      background: "#4417b6",
      title: title,
      description: desc1,
    },
    {
      icon1: playstore,
      icon2: appStore,
      color: "#000",
      background: "#bfe761",
      title: title2,
      description: desc2,
    },
    {
      icon1: Network,
      color: "#111",
      background: "#f2b49d",
      title: title3,
      description: desc3,
    },
    {
      icon1: layer,
      color: "#111",
      background: "#2598f2",
      title: title4,
      description: desc4,
    },
    {
      icon1: monitering,
      color: "#111",
      background: "#2598f2",
      title: title5,
      description: desc5,
    },
    {
      icon1: database,
      title: title6,
      description: desc6,
    },
    {
      icon1: server,
      title: title7,
      description: desc7,
    },
    {
      icon1: cloud,
      title: title8,
      description: desc8,
    },
    {
      icon1: social,
      title: title9,
      description: desc9,
    },
    {
      icon1: idea,
      title: title10,
      description: desc10,
    },
    {
      icon1: social2,
      title: title11,
      description: desc11,
    },
    {
      icon1: think2,
      title: title12,
      description: desc12,
    },
  ];

  // carousel settings..
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 0,
    autoplay: true,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  const mainHeadText = "Got an idea?".split();

  return (
    <section className="service pb-5 mb-4 mb-lg-0 mb-md-0 mt-md-4 mt-xx-l-5">
      <Container
        className="pt-5 pt-lg-0 pt-xl-5 mb-5 mb-lg-0 mt-lg-"
        id="services"
        ref={refElelm}
      >
        <Row>
          <Col lg={12} className="text-center mt-xxl-5">
            <div className="d-flex justify-content-center overflow-hidden">
              {mainHeadText.map((text, i) => {
                return (
                  <motion.h1 key={i} className="main-headlines ">
                    {text}
                  </motion.h1>
                );
              })}
            </div>
            <div
              className="content-span-wraper mt-4 overflow-hidden"
              ref={spanRef}
            >
              <motion.span className="cotent-typography ">
                Let&#39;s make it happen! Share your vision and we&#39;ll craft
                the perfect web and app solution with our team of experts.
                We&#39;re here to turn your dreams into digital reality. Ready
                to elevate your brand? Let&#39;s do this together!
              </motion.span>
            </div>
          </Col>
        </Row>
      </Container>

      {/* SHOWING THIS CONTAINER ONLY IN LARGE DEVICES AND HIDE IN MOBILE ,TAB */}
      <Container className="mt-5 pt-lg-5 position-relative hideIn-mobile">
        <Row className=" justify-content-lg-evenly">
          {/* <div className=" row service-mobile-row"> */}
          <Col
            lg={5}
            sm={2}
            md={2}
            xxl={5}
            className="mb-3  d-flex justify-content-center me-lg-5"
          >
            <Boxleft
              icon1={cad2}
              icon2={cad1}
              title={title}
              description={desc1}
            />
          </Col>
          <Col
            lg={5}
            sm={2}
            md={2}
            xxl={5}
            className="mb-3 d-flex justify-content-center "
          >
            <Boxright
              icon1={playstore}
              icon2={appStore}
              title={title2}
              description={desc2}
            />
          </Col>
          <Col
            lg={5}
            sm={2}
            xxl={5}
            className="mb-3 d-flex justify-content-center "
          >
            <Boxleft icon1={Network} title={title3} description={desc3} />
          </Col>
          <Col
            lg={5}
            sm={2}
            xxl={5}
            className="mb-3 d-flex justify-content-center "
          >
            <Boxright icon1={layer} title={title4} description={desc4} />
          </Col>
          <Col
            lg={5}
            sm={2}
            xxl={5}
            className="mb-3 d-flex justify-content-center "
          >
            <Boxleft icon1={monitering} title={title5} description={desc5} />
          </Col>
          <Col
            lg={5}
            sm={2}
            xxl={5}
            className="mb-3 d-flex justify-content-center "
          >
            <Boxright icon1={database} title={title6} description={desc6} />
          </Col>
          <Col
            lg={5}
            sm={2}
            xxl={5}
            className="mb-3 d-flex justify-content-center "
          >
            <Boxleft icon1={server} title={title7} description={desc7} />
          </Col>
          <Col
            lg={5}
            sm={2}
            xxl={5}
            className="mb-3 d-flex justify-content-center "
          >
            <Boxright icon1={cloud} title={title8} description={desc8} />
          </Col>
          <Col
            lg={5}
            sm={2}
            xxl={5}
            className="mb-3 d-flex justify-content-center "
          >
            <Boxleft icon1={social} title={title9} description={desc9} />
          </Col>
          <Col
            lg={5}
            sm={2}
            xxl={5}
            className="mb-3 d-flex justify-content-center "
          >
            <Boxright icon1={idea} title={title10} description={desc10} />
          </Col>
          <Col
            lg={5}
            sm={2}
            xxl={5}
            className="mb-3 d-flex justify-content-center "
          >
            <Boxleft icon1={social2} title={title11} description={desc11} />
          </Col>
          <Col
            lg={5}
            sm={2}
            xxl={5}
            className="mb-3 d-flex justify-content-center "
          >
            <Boxright icon1={think2} title={title12} description={desc12} />
          </Col>
          {/* </div> */}
        </Row>
        {/* svg  */}
        <div className="svg-wraper position-absolute">
          <svg
            width="5"
            height="1736"
            viewBox="0 0 5 1736"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="2.5"
              y1="2.91895"
              x2="2.49992"
              y2="1737.69"
              stroke="#E1E1E1"
              strokeWidth="5"
              strokeLinecap="round"
              strokeDasharray="0 10"
            />
          </svg>
        </div>
      </Container>

      {/* DISPLAYING THIS SECTION FOR CAROUSEL IN MOBILE AND TAB  */}
      <Container className="d-flex flex-column  justify-content-center carousel-wraper">
        <div className="slider-container">
          <Slider {...settings}>
            {CarouselData.map((item, i) => {
              return <Cards key={i} {...item} />;
            })}
          </Slider>
        </div>
      </Container>
    </section>
  );
};

export default Services;

// backup
