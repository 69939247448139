import React from "react";
import Marquee from "react-fast-marquee";
// images
import Meterro from "../Assets/Images/LogoScroll/meteor-logo-blue-orange 1.png";
import NestLogo from "../Assets/Images/LogoScroll/nestLogo.png";
import MongoDb from "../Assets/Images/LogoScroll/Mongodb 1.png";
import Flutter from "../Assets/Images/LogoScroll/Google-flutter-logo 1.png";
import Nextjs from "../Assets/Images/LogoScroll/Nextjs-logo 1.png";
import Elastic from "../Assets/Images/LogoScroll/elastic.png";
import Postrage from "../Assets/Images/LogoScroll/postgre.png";
import ReactJS from "../Assets/Images/LogoScroll/react.png";
import GA from "../Assets/Images/LogoScroll/analytics.png";
import jenkins from "../Assets/Images/LogoScroll/jenkins.png";
import devtron from "../Assets/Images/LogoScroll/devtron.png";
import kubernetes from "../Assets/Images/LogoScroll/kubernets.png";
import Grafana from "../Assets/Images/LogoScroll/grafana.png";
import node from "../Assets/Images/LogoScroll/node.png";
import Angular from "../Assets/Images/LogoScroll/angular.png";
import Golang from "../Assets/Images/LogoScroll/goLang.png";
import Python from "../Assets/Images/LogoScroll/python.png";
import AWS from "../Assets/Images/LogoScroll/aws.png";
import Harbor from "../Assets/Images/LogoScroll/harbor.png";
import Firbase from "../Assets/Images/LogoScroll/firebase.png";

const LogoSlider = ({direction}) => {
  // CONST IMAGE MAPING ARRAY
  const logoScrollData = [
    {
      id: "Meterro",
      logoPath: Meterro,
    },
    {
      id: "NestLogo",
      logoPath: NestLogo,
    },
    {
      id: "MongoDB",
      logoPath: MongoDb,
    },
    {
      id: "Flutter",
      logoPath: Flutter,
    },
    {
      id: "Nextjs",
      logoPath: Nextjs,
    },

    {
      id: "Elastic",
      logoPath: Elastic,
    },
    {
      id: "Postrage",
      logoPath: Postrage,
    },
    {
      id: "ReactJS",
      logoPath: ReactJS,
    },
    {
      id: "GooleAnalytics",
      logoPath: GA,
    },
    {
      id: "jenkins",
      logoPath: jenkins,
    },
    {
      id: "devtron",
      logoPath: devtron,
    },
    {
      id: "kubernetes",
      logoPath: kubernetes,
    },
    {
      id: "Grafana",
      logoPath: Grafana,
    },
    {
      id: "node",
      logoPath: node,
    },
    {
      id: "Angular",
      logoPath: Angular,
    },
    {
      id: "Golang",
      logoPath: Golang,
    },
    {
      id: "Python",
      logoPath: Python,
    },
    {
      id: "AWS",
      logoPath: AWS,
    },
    {
      id: "Harbor",
      logoPath: Harbor,
    },
    {
      id: "Firbase",
      logoPath: Firbase,
    },
  ];

  return (
    <section className="logoscroll mb-lg-5 py-4 pt-md-0 pt-lg-0">
      <Marquee autoFill direction={direction}>
        {logoScrollData.map((logo, i) => {
          return (
            <div key={i} className="logos-wraper">
              <img src={logo.logoPath} alt={logo.id} />
            </div>
          );
        })}
      </Marquee>
    </section>
  );
};

export default LogoSlider;
