import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BannerData } from "../Assets/data/Contents";
import { NavLink } from "react-router-dom";

const Banner = () => {
  return (
    <section className="banner-section mt-md-5 " id="offers">
      <Container className="banner-container d-md-flex flex-md-column align-items-md-center justify-content-md-center">
        <Row className="position-relative custom-row p-3 p-lg-0">
          {BannerData.map((item, i) => {
            return (
              <Col
                key={i}
                className="banner-line py-5 py-lg-0 mb-2 px-md-5"
                lg={4}
                sm={12}
              >
                <div>
                  <h3>{item.title1}</h3>
                  <span>{item.title2}</span>
                </div>
              </Col>
            );
          })}
        </Row>
        <NavLink to="/project-estimate" className="mobile-estimate-btn ">
          <button className="">Get an Estimate</button>
        </NavLink>
      </Container>
    </section>
  );
};

export default Banner;
