import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Services from "./Services";
import Footer from "./Footer";
import BannerImage from "../Assets/Images/EVR_LOGO.png";

const NewServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      className="position-relative mt-5"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className="container ">
        <div className="mobile_logo">
          <img src={BannerImage} alt="EvolvierLogo" />
        </div>
      </div>
      <Services />
      <Footer />
    </motion.div>
  );
};

export default NewServices;
