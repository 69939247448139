import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Designer from "../Assets/Images/Designer (23) 1.png";
import BannerImage from "../Assets/Images/EVR_LOGO.png";
import {
  towDAnimation,
  threeDAnimation,
  amfxAddditonlData1,
  amdfAddData2,
} from "../Assets/data/Contents";
import Contact from "./Contact";
import Footer from "./Footer";
import amvfxImage from "../Assets/Images/amvfxImage.png";
import Amvfxcard from "../ReusedComponentssssssssssssssssssss/Cards/Amvfxcard";
import Slider from "react-slick";
import { motion } from "framer-motion";

const AnimationVfx = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // carousel settings..
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 0,
    autoplay: true,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <section className="amvfx-section ">
        <div className="mobile_logo">
          <img src={BannerImage} alt="EvolvierLogo" />
        </div>
        <Container className="pt-5 pt-lg-0">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h1 className="amvfx-h1 mb-3 mb-md-5 text-center">
              Animation & VFX Solutions
            </h1>
            <span className="amvfx-span mt-lg-4 mb-4">
              Let&apos;s bring your vision to life! Share your ideas, and our
              team of experts will craft the perfect Animation and VFX solution
              tailored just for you. We&apos;re here to transform your dreams
              into stunning digital realities. Ready to elevate your brand with
              captivating visuals? Let&apos; s make it happen together!
            </span>
            <motion.div
              initial={{ y: 50 }}
              whileInView={{ y: 0 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
              className="amvfx-img-wrapper mt-lg-5"
            >
              <img src={Designer} alt="" />
            </motion.div>
          </div>
        </Container>
        {/* hiding this section in mobile devices  */}
        <section className="am-vfx-row">
          <Container className="position-relative ">
            <Row className="justify-content-between">
              {/* first column */}
              <Col lg={5} xxl={5} className="text-center position-xxl-relative">
                <h3 className="am-vfx-h3 text-end mb-lg-5">
                  2D <span>Animation</span>
                </h3>
                {towDAnimation.map((card, i) => {
                  return (
                    <Amvfxcard
                      {...card}
                      key={i}
                      xDirection={-50}
                      textDirection="text-end"
                    />
                  );
                })}
                <div className="amfImage ms-xxl-4 position-xxl-absolute">
                  <img src={amvfxImage} alt="" />
                </div>
              </Col>
              {/* second column */}
              <Col lg={5} xxl={5} className="text-center">
                <h3 className="am-vfx-h3 mb-lg-5 text-start">
                  3D <span>Animation</span>
                </h3>
                {threeDAnimation.map((card, i) => {
                  return (
                    <Amvfxcard
                      {...card}
                      key={i}
                      xDirection={50}
                      textDirection="text-start"
                    />
                  );
                })}
              </Col>
            </Row>
            <div className="am-vfx-svg-wrapper position-absolute">
              <svg
                width="6"
                height="1955"
                viewBox="0 0 6 1955"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="2.625"
                  y1="2.93652"
                  x2="2.62509"
                  y2="1957.08"
                  stroke="#E1E1E1"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeDasharray="0 10"
                />
              </svg>
            </div>
          </Container>
        </section>
        {/* showing this section only in mobile and tab for card carousel */}
        <Container className="mt-5">
          <div className="slider-container">
            <h3 className="am-vfx-h3 text-center text-lg-end text-md-center mb-lg-5">
              2D <span>Animation</span>
            </h3>
            <Slider {...settings}>
              {towDAnimation.map((item, i) => {
                return (
                  <Amvfxcard key={i} {...item} textDirection="text-center" />
                );
              })}
            </Slider>
          </div>

          <div className="slider-container mt-5 pt-md-5">
            <h3 className="am-vfx-h3 text-center text-lg-end text-md-center mb-lg-5">
              3D <span>Animation</span>
            </h3>
            <Slider {...settings}>
              {threeDAnimation.map((item, i) => {
                return (
                  <Amvfxcard key={i} {...item} textDirection="text-center" />
                );
              })}
            </Slider>
          </div>
        </Container>

        <Container className="am-vfx-addSection mb-lg-5 text-center position-relative">
          <h1 className="am-vfx-add-h3 mb-4 mb-lg-5">Additional Services</h1>
          <Row className="justify-content-between">
            <Col lg={5} sm={12} className="add-md-sect">
              {amfxAddditonlData1.map((item, i) => {
                return (
                  <Amvfxcard {...item} key={i} textDirection="text-lg-end" />
                );
              })}
            </Col>
            <Col lg={5} sm={12} className="add-md-sect">
              {amdfAddData2.map((item, i) => {
                return (
                  <Amvfxcard {...item} key={i} textDirection="text-lg-start" />
                );
              })}
            </Col>
          </Row>
          <div className="am-vfx-addS-svg position-absolute">
            <svg
              width="6"
              height="291"
              viewBox="0 0 6 291"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="2.625"
                y1="3.12109"
                x2="2.62501"
                y2="287.928"
                stroke="#E1E1E1"
                strokeWidth="5"
                strokeLinecap="round"
                strokeDasharray="0 10"
              />
            </svg>
          </div>
        </Container>
      </section>
      <div className="wraper-contact">
        <Contact />
      </div>
      <Footer />
    </motion.div>
  );
};

export default AnimationVfx;
