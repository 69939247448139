/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import { NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import ContactModal from "../ReusedComponentssssssssssssssssssss/modal/ContactModal";
import BannerImage from "../Assets/Images/EVR_LOGO.png";

const Navmenu = () => {
  const location = useLocation();
  const defaultPath = "/";
  const pathLocation = location.pathname;
  const navLinksData = [
    {
      path: "#home",
      page: "Home",
      id: "home",
      navLink: false,
    },
    {
      path: "/services",
      page: "Services",
      id: "service",
      navLink: true,
    },
    {
      path: "/about",
      page: "About",
      id: "About",
      navLink: true,
    },
    {
      path: "#contact",
      page: "Contact Us",
      id: "Contact",
      navLink: false,
    },
  ];

  const [scrolled, setScorlled] = useState(false);
  const [navActive, setNavActive] = useState("Home");

  const TopNav = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScorlled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav className={scrolled ? "navmenubar scrolled" : "navmenubar"}>
      <Container className="py-lg-2  mobile-nav py-3">
        <div className="d-flex py-xxl-4 justify-content-center justify-content-lg-between  align-items-center">
          <ul className="links mb-0 p-0">
            {navLinksData.map((item, i) => {
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <li
                  className={`${item.id2 === navActive ? "" : ""} link`}
                  id={item.id}
                  key={i}
                  onClick={() => {
                    // setNavActive(item.id2);
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "NavBar_Link_Clicks",
                      Url_Action: `NavBar ${item.id}_url Cliked`,
                    });
                  }}
                >
                  <Link
                    onClick={() => {
                      setNavActive(item.id2);
                      TopNav();
                    }}
                    smooth
                    to={
                      (pathLocation !== defaultPath && item.path === "#home") ||
                      (item.path === "#contact" &&
                        pathLocation == "/services") ||
                      (item.path === "#contact" &&
                        pathLocation == "/project-estimate")
                        ? // (item.path === "#contact" && pathLocation !== defaultPath)
                          "/"
                        : item.path
                    }
                    id={item.id}
                    // className="test"
                    className={`${
                      item.id2 === navActive ? "isLinkActive" : ""
                    } test`}
                  >
                    {item.page}
                  </Link>

                  {/* second link for hover animation */}

                  <Link
                    onClick={() => {
                      TopNav();
                      // setNavActive(item.id2);
                    }}
                    smooth
                    to={
                      (pathLocation !== defaultPath && item.path === "#home") ||
                      (item.path === "#contact" &&
                        pathLocation == "/services") ||
                      (item.path === "#contact" &&
                        pathLocation == "/project-estimate")
                        ? // (item.path === "#contact" && pathLocation !== defaultPath)
                          "/"
                        : item.path
                    }
                    id={item.id}
                    // className="test"
                    className={`${
                      item.id2 === navActive ? "isLinkActive" : ""
                    } test link_2`}
                  >
                    {item.page}
                  </Link>
                </li>
              );
            })}
          </ul>

          <Link smooth to={pathLocation !== defaultPath && "/"}>
            <motion.div className="nav-brand-logo ">
              <img src={BannerImage} alt="" />
            </motion.div>
          </Link>

          {/* SHOW THIS ONLY IN MOBILE AND TAB */}
          <div className="contact-btn-modal">
            <ContactModal />
          </div>

          {/* PROJECT ESTIMATE BUTTON */}
          <NavLink 
            to="/project-estimate" 
            className="estimate_a"
            onClick={TopNav}
           >
            <button
              type="button"
              className="p-estimate-btn"
              onClick={() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "Navbar-estimate-btn-action",
                  Url_Action: `NavBar estimate button Cliked`,
                });
              }}
            >
              Get an Estimate
            </button>
          </NavLink>
        </div>
      </Container>
    </nav>
  );
};

export default Navmenu;
